<template>
  <div>
    <b-button
      @click="$refs.modal.show()"
      variant="outline-primary"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('ACTIONS.insert_flag') : ''"
    >
      <b-icon icon="flag-fill" variant="primary" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('ACTIONS.insert_flag') }}</span>
    </b-button>

    <b-modal
      ref="modal"
      :title="$t('ACTIONS.insert_flag')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="flagState"
          :label="$t('TERMS.flag')"
          label-for="name-input"
          :invalid-feedback="$t('FORMS.required')"
        >
          <b-form-input
            id="name-input"
            v-model="flag"
            :state="flagState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <loading :loading="isLoading"/>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastsMixin from '@/services/mixins/toasts.mixins'

export default {
  name: 'InsertFlag',
  mixins: [ToastsMixin],
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    machineId: { required: true },
    labId: { required: false, default: null },
    pillId: { required: false, default: null }
  },
  data () {
    return {
      flag: '',
      flagState: null,
      submittedNames: []
    }
  },
  methods: {
    ...mapActions({
      submitInsertFlag: 'labs/insertFlag',
      submitPillInsertFlag: 'pills/insertFlag'
    }),
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.flagState = valid
      return valid
    },
    resetModal () {
      this.flag = ''
      this.flagState = null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.action({ ...this.params, flag: this.flag })
        .then(response => {
          if (response.status !== 200 || response.data.status !== 'ok' || !response.data.data.unlocked) {
            if (typeof response.data.data === 'string') {
              throw Error(response.data.data)
            } else {
              throw Error('invalid_flag')
            }
          }
        })
        .then(_ => {
          this.showSuccessToast(this.$t('MACHINES.FLAGS.valid'))
          this.$emit('success')

          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
        .catch(err => {
          switch (err.message) {
            case 'invalid_flag': {
              this.showErrorToast(this.$t('MACHINES.FLAGS.invalid'))
              break
            }
            default: {
              this.showErrorToast(this.$t('MACHINES.FLAGS.error'))
            }
          }
        })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'labs/isLoading'
    }),
    isLabMachine () { return this.labId !== null },
    action () { return this.isLabMachine ? this.submitInsertFlag : this.submitPillInsertFlag },
    params () { return this.isLabMachine ? { lab: this.labId, machine: this.machineId } : { pill: this.pillId, machine: this.machineId } }
  }
}
</script>
