<template>
  <div>
    <b-button @click="startMachine" variant="secondary"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('ACTIONS.lock_machine') : ''">

      <b-icon icon="lock-fill" variant="white" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('ACTIONS.lock_machine') }}</span>
    </b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LockLabMachine',
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    machineId: { required: true },
    labId: { required: false, default: null },
    pillId: { required: false, default: null }
  },
  methods: {
    ...mapActions({
      startLabMachine: 'labs/startMachine',
      startPillMachine: 'pills/startMachine',
      getUser: 'auth/getUser'
    }),
    startMachine () {
      return this.action(this.params)
        .then(_ => this.getUser()) // Used to refresh the current blocked machine
        .then(_ => this.$emit('success'))
        .catch(err => this.$emit('error', err))
    }
  },
  computed: {
    isLabMachine () { return this.labId !== null },
    action () { return this.isLabMachine ? this.startLabMachine : this.startPillMachine },
    params () { return this.isLabMachine ? { lab: this.labId, machine: this.machineId } : { pill: this.pillId, machine: this.machineId } }
  }

}
</script>
