<template>
  <div>
    <b-button
      @click="$refs.modal.show()"
      variant="danger"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('LAB_MACHINE.ACTIONS.revert') : ''"
    >
      <b-icon icon="arrow-counterclockwise" variant="white" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('LAB_MACHINE.ACTIONS.revert') }}</span>
    </b-button>

    <b-modal
      ref="modal"
      :title="$t('LAB_MACHINE.confirm_revert_title')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="revertMachine"
    >
      <p>{{ $t('LAB_MACHINE.confirm_revert_text') }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RevertLabMachine',
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    machineId: { required: true },
    labId: { required: false, default: null },
    pillId: { required: false, default: null }
  },
  methods: {
    ...mapActions({
      revertLabMachine: 'labs/revertMachine',
      revertPillMachine: 'pills/revertMachine'
    }),
    revertMachine () {
      return this.action(this.params)
        .then(_ => this.$emit('success'))
        .catch(err => this.$emit('error', err))
    }
  },
  computed: {
    isLabMachine () { return this.labId !== null },
    action () { return this.isLabMachine ? this.revertLabMachine : this.revertPillMachine },
    params () { return this.isLabMachine ? { lab: this.labId, machine: this.machineId } : { pill: this.pillId, machine: this.machineId } }
  }
}
</script>
