<template>
  <div>
    <b-button
      @click="$refs.modal.show()"
      variant="danger"
      :block="!onlyIcon"
      v-b-tooltip.hover.bottom="onlyIcon ? $t('ACTIONS.unlock_machine') : ''"
    >
      <b-icon icon="unlock-fill" variant="white" />
      <span v-if="!onlyIcon" class="ml-1">{{ $t('ACTIONS.unlock_machine') }}</span>
    </b-button>

    <b-modal
      ref="modal"
      :title="$t('LAB_MACHINE.confirm_unlock_title')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="stopMachine"
    >
      <p>{{ $t('LAB_MACHINE.confirm_unlock_text') }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UnlockLabMachine',
  props: {
    onlyIcon: { type: Boolean, required: false, default: false },
    noEvent: { type: Boolean, required: false, default: false },
    machineId: { required: true },
    labId: { required: false, default: null },
    pillId: { required: false, default: null }
  },
  methods: {
    ...mapActions({
      stopLabMachine: 'labs/stopMachine',
      stopPillMachine: 'pills/stopMachine',
      stopUserMachine: 'user/stopMachine',
      getUser: 'auth/getUser'
    }),
    stopMachine () {
      return this.action(this.params)
        .then(_ => this.$emit('success'))
        .then(_ => this.noEvent ? null : this.$root.$emit('machineStoppedOrUnlocked', { machineId: this.machineId }))
        .then(_ => this.getUser())
        .catch(_ => this.$emit('error'))
    }
  },
  computed: {
    isLabMachine () { return this.labId !== null },
    isPillMachine () { return this.pillId !== null },
    action () {
      if (this.isLabMachine) {
        return this.stopLabMachine
      }

      if (this.isPillMachine) {
        return this.stopPillMachine
      }

      return this.stopUserMachine
    },
    params () {
      if (this.isLabMachine) {
        return { lab: this.labId, machine: this.machineId }
      }

      if (this.isPillMachine) {
        return { pill: this.pillId, machine: this.machineId }
      }

      return { machine: this.machineId }
    }
  }
}
</script>
